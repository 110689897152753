import { FAVICON_URL, IMAGE_PATH, SITE_TITLE } from "@helpers/config";
import axios from "axios";
import { getAdsBannerRespApiBk } from "./ads/getAdsBannerRespApi";
import { GetServerSidePropsContext } from "next";

export const getBanner = async (
  langCode: "en-US"
): Promise<BannerResponseType | null> => {
  try {
    const response = await axios.post(`/api/layout/getBanners`, {
      langCode,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
type ArgsType = {
  url: string;
  args: {
    langCode: string;
    orgId: number;
  };
};
export const fetcherGetFooter = async (
  params: ArgsType
): Promise<FooterConfigDataType> => {
  return axios
    .post(params.url, {
      ...params.args,
    })
    .then((res) => res.data)
    .then((res) => res.footerConfig.data[0]);
};

export const getFooter = async (langCode = "en-US", orgId?: number) => {
  try {
    const response = await axios.post(`/api/layout/getFooter`, {
      langCode,
      orgId,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getHeader = async (langCode = "en-US") => {
  try {
    const response = await axios.post(`/api/layout/getHeader`, {
      langCode,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};
export const getCurrencyInfo = async (langCode = "en-US") => {
  try {
    const response = await axios.post(`/api/layout/getCurrencyinfo`, {
      langCode,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const gerWhiteLabelLang = async (
  keyWord: string,
  pathName: string,
  org_id?: number
) => {
  try {
    const response = await axios.post(`/api/layout/getWhiteLabelLan`, {
      keyWord,
      pathName,
      org_id,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getUserProfileAPI = async (
  usrType = 2
): Promise<UserProfileType | null> => {
  try {
    const response = await axios.post(`/api/profile/getUserProfile`, {
      usr_type: usrType,
    });
    return response.data.data.data;
  } catch (error) {
    return null;
  }
};

export const getTestimonialsinfo = async (langCode = "en-US") => {
  try {
    const response = await axios.post(`/api/layout/getTestimonialsinfo`, {
      langCode,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getFavicon = async (): Promise<FaviconResponseType | null> => {
  try {
    const response = await axios.post(`/api/layout/getFavicon`, {});
    return response.data.data;
  } catch (error) {
    return null;
  }
};

export const getLoader = async (): Promise<LoaderResponseType | null> => {
  try {
    const response = await axios.post(`/api/layout/getLoader`, {});
    return response?.data?.data;
  } catch (error) {
    return null;
  }
};

export const getUserCalType = async (userType: number, ip: string) => {
  try {
    let json = {
      usr_type: userType,
      client_ip_address: ip,
      "true-client-ip": ip,
    };
    const response = await axios.post(`/api/getUserCalType`, { json });
    return response.data?.data?.data[0]?.calender_type;
  } catch (error) {
    return null;
  }
};

export const getUserDateFormat = async (json: any): Promise<string> => {
  try {
    const response = await axios.post(`/api/getUserDateFormat`, { json });
    return response.data?.data?.data?.dateFormat;
  } catch (error) {
    return "DD/MM/YYYY";
  }
};

type ArgsCurrencyInfoType = {
  url: string;
  args: {};
};
export const fetcherGetCurrencyInfo = async (
  params: ArgsCurrencyInfoType
): Promise<CurrencylanguageinfoConfigType> => {
  return axios
    .post(params.url, {
      ...params.args,
    })
    .then((res) => res.data);
};

type ArgsWhiteLabelsType = {
  url: string;
  args: {
    keyWord: string;
    pathName: string;
    orgId?: number;
  };
};
export const fetcherGetWhiteLabelLan = async (
  params: ArgsWhiteLabelsType
): Promise<LanguageResultType[]> => {
  return axios
    .post(params.url, {
      ...params.args,
    })
    .then((res) => res.data.headerConfig.data);
};

export const getFaviconFromConfig = (
  data: ConfigApiType,
  config?: DomainsConfigType | null
) => {
  const imageUrl = config?.bannerPath;

  const faviconUrl = config?.faviconUrl;

  let siteTitle = config?.siteTitle;
  let favPath = faviconUrl;

  // * if not exists in config file then its taken from api
  if (!siteTitle) {
    siteTitle = data.favicon.text;
  }

  if (!favPath) {
    favPath = `${imageUrl}${data.favicon.icon}`;
  }

  return {
    icon: favPath,
    text: siteTitle,
  };
};

export const getConfig = async (params?: {
  lang?: string;
}): Promise<{
  config?: ConfigApiType;
  externalConfig?: DomainsConfigType;
} | null> => {
  const navAgent = navigator.userAgent;
  return axios
    .post("/api/layout/getConfig", {
      ...params,
      browser: navAgent,
    })
    .then((res) => res.data)
    .catch(() => null);
};
export const getConfigBK = async (
  domain: string,
  headers: any,
  params?: {
    lang?: string;
  }
): Promise<{
  config?: ConfigApiType;
  externalConfig?: DomainsConfigType;
} | null> => {
  return axios
    .post(
      domain + "/api/layout/getConfig",
      {
        ...params,
      },
      {
        headers,
      }
    )
    .then((res) => res.data)
    .catch(() => null);
};
export const getFaviconFromConfigBK = (config?: ConfigApiType | null) => {
  const imageUrl = IMAGE_PATH;

  const faviconUrl = FAVICON_URL;

  let siteTitle = SITE_TITLE;
  let favPath = faviconUrl;

  // * if not exists in config file then its taken from api
  if (!siteTitle) {
    siteTitle = config?.favicon.text;
  }

  if (!favPath) {
    favPath = `${imageUrl}${config?.favicon?.icon}`;
  }

  return {
    icon: favPath,
    text: siteTitle,
  };
};

/**
 * * This method is used to get the config data from the external api (Mongo)
 */
export const getExternalConfig = async (
  orgId?: number
): Promise<DomainsConfigType | null> => {
  return axios
    .post("/api/layout/getExternalConfig", {
      orgId,
    })
    .then((res) => res.data.data)
    .catch(() => null);
};

export const getServerSideBanner = async (
  context: GetServerSidePropsContext,
  pathName = "/",
  section = "hotels"
) => {
  // get cookies
  const cookies = context.req.cookies;
  const host = context.req.headers.host || "";
  let orgId = cookies.orgid || "";
  let url = `https://${host}`;

  // check if its localhost
  if (host.includes("localhost")) {
    url = `http://${host}`;
  }
  const headers = {
    ...context.req.headers,
    origin: url,
  };

  if (!(Number(orgId) > 0)) {
    // here we can set the orgId from the get config api
    const config = await getConfigBK(url, headers, {});
    if (config?.config?.orgBySite) {
      orgId = String(config?.config?.orgBySite.orgId);
    }
  }

  const data = await getAdsBannerRespApiBk(
    {
      pathName,
      orgId: Number(orgId),
      section,
    },
    url,
    headers
  );

  return {
    adsBanner: Array.isArray(data) ? data : [],
  };
};

export const getCrispTokenId = async (
  prefix: string
): Promise<string | null> => {
  return axios
    .post("/api/layout/getCrispTokenId", {
      prefix,
    })
    .then((res) => res.data.tokenId)
    .catch(() => null);
};
